import React from "react";
import { Input } from "theme-ui";
import Frame from "./Frame";

const NumberInput = ({ register, icon, ...props }) => {
  return (
    <Frame {...props} name={register.name}>
      {icon}
      <Input id={register.name} placeholder=" " type="text" {...register} />
    </Frame>
  );
};

export default NumberInput;
