import * as React from "react";

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  IconButton,
  Paragraph,
  Text,
} from "theme-ui";
import { useForm } from "react-hook-form";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import TextField from "components/Fields/Text";
import EmailField from "components/Fields/Email";
import NumberField from "components/Fields/Number";
import PasswordField from "components/Fields/Password";
import SelectField from "components/Fields/Select";
import PhoneNumber from "components/Fields/PhoneNumber";
import Alert from "components/Alert";
import Card from "components/card/Card";
import CardWithBlackBackground from "components/card/CardWithBlackBackground";
import Layout from "components/Layout";
import PhotoGallery from "../components/PhotoGallery";
import { useVariantByBreakpoint } from "utils";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import Slider from "../components/Slider";
import RequestForm from "components/RequestForm";
import BrandLogo from "components/BrandLogo";
import DatePickerField from "components/Fields/DatePicker/DatePicker";
import DoubleDatePickerField from "components/Fields/DoubleDatePicker";
import SimpleInput from "components/Fields/DatePicker/SimpleInput";
import StikyMenu from "components/StickyMenu";
import LimitedLines from "components/LimitedLines";
import Icon from "components/Icons";

const IndexPage = () => {
  const { register, handleSubmit, setValue, control } = useForm();

  const { allImageSharp, strapiVendor } = useStaticQuery(graphql`
    query {
      allImageSharp(limit: 12) {
        edges {
          node {
            gatsbyImageData
          }
        }
      }
      strapiVendor(availability_check_enabled: { eq: true }) {
        slug
        axpVendorId: axp_vendor_id
      }
    }
  `);

  const images = allImageSharp.edges.map((item) => getImage(item.node));

  const variantSelector = useVariantByBreakpoint();

  return (
    <Layout page="Explore">
      <Heading id="main-section" as="h1" sx={{ my: "40px" }}>
        Components
      </Heading>
      <i className="fas fa-facebook" />
      <BrandLogo />
      <Heading as="h2" sx={{ mb: "10px" }}>
        Variant by breakpoints
      </Heading>
      <Button
        variant={variantSelector(["outlined", "text", "primary.small"])}
        sx={{
          mb: 10,
          width: "min-content",
        }}
      >
        {variantSelector(["outlined", "text", "primary.small"])}
      </Button>
      <StikyMenu
        items={[
          {
            text: "Components",
            id: "main-section",
          },
          {
            text: "Buttons",
            id: "buttons-section",
          },
          {
            text: "Typography",
            id: "typography-section",
          },
          {
            text: "Fields",
            id: "fields-section",
          },
          {
            text: "Alerts",
            id: "alerts-section",
          },
          {
            text: "Check Availability",
            id: "check-availability-section",
          },
          {
            text: "Cards",
            id: "cards-section",
          },
          {
            text: "Photo Gallery",
            id: "photo-gallery-section",
          },
        ]}
      />
      <Heading
        id="buttons-section"
        as="h2"
        sx={{ mb: "10px", scrollPaddingTop: "300px" }}
      >
        Buttons
      </Heading>
      <Grid
        sx={{
          gridTemplateColumns: "max-content max-content",
          justifyContent: "center",
          alignItems: "center",
          mb: "40px",
        }}
      >
        <Text>Large Button</Text>
        <Text>Small Button</Text>
        <Button>Click me!</Button>
        <Button variant="primary.small">Click me!</Button>
        <Button variant="outlined">Click me!</Button>
        <Button variant="outlined.small">Click me!</Button>
        <Button variant="text">Click me!</Button>
        <Button variant="text.small">Click me!</Button>
        <IconButton variant="icon.solid" sx={{ justifySelf: "center" }}>
          <ArrowLeft />
        </IconButton>
        <IconButton
          variant="icon.solid"
          disabled
          sx={{ justifySelf: "center" }}
          className="fas fa-chevron-right"
        />
        <IconButton variant="icon.light" sx={{ justifySelf: "center" }}>
          <Icon icon="fas fa-chevron-left" />
        </IconButton>
        <IconButton
          variant="icon.light"
          disabled
          sx={{ justifySelf: "center" }}
        >
          <ArrowRight />
        </IconButton>
      </Grid>
      <Heading
        id="typography-section"
        as="h2"
        sx={{ mb: "10px", scrollMarginTop: "300px" }}
      >
        Typography
      </Heading>
      <Flex sx={{ flexDirection: "column", mb: "40px" }}>
        <Heading as="h1" variant="heading1">
          H1
        </Heading>
        <Heading as="h2" variant="heading2">
          H2
        </Heading>
        <Heading as="h3" variant="heading3">
          H3
        </Heading>
        <Heading as="h4" variant="heading4">
          H4
        </Heading>
        <Heading as="h5" variant="heading5">
          H5
        </Heading>
        <Heading as="h6" variant="heading6">
          H6
        </Heading>
        <Paragraph variant="subtitle1">Subtitle 1</Paragraph>
        <Paragraph variant="subtitle2">Subtitle 1</Paragraph>
        <Paragraph variant="body1">Body 1</Paragraph>
        <Paragraph variant="body2">Body 2</Paragraph>
        <Paragraph variant="body3">Body 3</Paragraph>
        <Text variant="button1">Button 1</Text>
        <Text variant="button2">Button 2</Text>
        <Text variant="button3">Button 3</Text>
        <Text variant="caption">Caption</Text>
        <Text variant="overline">overline</Text>
        <LimitedLines
          variant={variantSelector(["body2", "overline"])}
          lines={[3, null, 5]}
          sx={{
            width: 100,
            maxWidth: "full",
          }}
        >
          Limited lines Paragraph, Limited lines Paragraph, Limited lines
          Paragraph, Limited lines Paragraph, Limited lines Paragraph, Limited
          lines Paragraph, Limited lines Paragraph, Limited lianes Paragraph,
          Limited lines Paragraph, Limited lines Paragraph, Limited lines
          Paragraph, Limited lines Paragraph, Limited lines Paragraph, Limited
          lines Paragraph, Limited lines Paragraph, Limited lines Paragraph,
          Limited lines Paragraph, Limited lines Paragraph, Limited lines
          Paragraph, Limited lines Paragraph, Limited lines Paragraph, Limited
          lines Paragraph, Limited lines Paragraph, Limited lines Paragraph,
          Limited lines Paragraph, Limited lines Paragraph, Limited lines
          Paragraph
        </LimitedLines>
      </Flex>
      <Heading id="fields-section" as="h2" sx={{ mb: "10px" }}>
        Fields
      </Heading>
      <Flex
        as="form"
        sx={{
          flexDirection: "column",
          gap: "16px",
          maxWidth: "300px",
          mb: 10,
        }}
        onSubmit={handleSubmit(console.log)}
      >
        <TextField label="Text Field" register={register("text")} />
        <EmailField label="Email Field" register={register("email")} />
        <NumberField label="Number Field" register={register("number")} />
        <PasswordField label="Password Field" register={register("pass")} />
        <PhoneNumber
          label="Phone Field"
          register={register("phone")}
          setValue={setValue}
        />
        <SelectField
          label="Select Field"
          control={control}
          name="select"
          menuTitle="Menu Title"
          withHeader
          options={[
            {
              value: "a",
              label: "a",
            },
            {
              value: "b",
              label: "b",
            },
          ]}
        />
        <DatePickerField
          singleValue
          input={
            <SimpleInput
              label="Date picker Field"
              name="calendar"
              control={control}
            />
          }
        />
        <DoubleDatePickerField
          input={
            <SimpleInput
              label="Double date picker Field"
              name="double-calendar"
              control={control}
            />
          }
          disabledPrevDays
        />
        <Button type="submit">Submit</Button>
      </Flex>
      <Heading id="alerts-section" as="h2" sx={{ mb: 2.5 }}>
        Alerts
      </Heading>
      <Flex sx={{ flexDirection: "column", gap: 8, mb: 10 }}>
        <Alert
          variant="success"
          data={{
            title: "Success Title",
            description: "<Success descriptions ------------ >",
            helper: "Helper",
          }}
          isOpen={true}
        />
        <Alert
          variant="info"
          data={{
            title: "Info Title",
            description: "<Info descriptions ------------ >",
            helper: "Helper",
          }}
          isOpen={true}
        />
        <Alert
          variant="warning"
          data={{
            title: "Warning Title",
            description: "<Warning descriptions ------------ >",
            helper: "Helper",
          }}
          isOpen={true}
        />
        <Alert
          variant="error"
          data={{
            title: "Verification failed.",
            description:
              "Please check your details. Having issues? Please contact us.",
            helper: "Helper",
          }}
          isOpen={true}
        />
      </Flex>

      <Heading id="check-availability-section" as="h2" sx={{ mb: "10px" }}>
        Check Availability
      </Heading>

      <RequestForm
        axpVendorId={strapiVendor.axpVendorId}
        availabilityCheckEnabled
        isSignedIn
        sx={{
          mb: 10,
        }}
      />

      <Heading id="cards-section" as="h2" sx={{ mb: "10px" }}>
        Cards
      </Heading>
      <Grid
        sx={{
          gridTemplateColumns: "repeat(4, max-content)",
          gap: "16px",
          overflow: "auto",
          mb: 10,
        }}
      >
        {["extra_large", "large", "medium", "small"].map((size) =>
          ["white", "outlined", "no_background", "black_background"].map(
            (type) =>
              type === "black_background" ? (
                <Flex sx={{ gap: "12px" }}>
                  <CardWithBlackBackground
                    image={images[0]}
                    size={size}
                    type={type}
                    orientation="portrait"
                  >
                    <Box>this is an example</Box>
                  </CardWithBlackBackground>
                  {size !== "small" && (
                    <CardWithBlackBackground
                      image={images[0]}
                      size={size}
                      type={type}
                      orientation="landscape"
                    >
                      <Box>this is an example</Box>
                    </CardWithBlackBackground>
                  )}
                </Flex>
              ) : (
                <Flex sx={{ gap: "12px" }}>
                  <Card
                    image={images[0]}
                    size={size}
                    type={type}
                    orientation="portrait"
                  >
                    <Box>this is an example</Box>
                  </Card>
                  {size !== "small" && (
                    <Card
                      image={images[0]}
                      size={size}
                      type={type}
                      orientation="landscape"
                    >
                      <Box>this is an example</Box>
                    </Card>
                  )}
                </Flex>
              )
          )
        )}
      </Grid>
      <Heading id="photo-gallery-section" as="h2" sx={{ mb: 2.5 }}>
        Photo Gallery
      </Heading>
      <PhotoGallery medias={images}></PhotoGallery>

      <Slider images={images}>
        {["0", "1", "2", "3", "4", "5", "6", "7", "8"].map((i) => (
          <Card
            image={images[0]}
            size="large"
            type="white"
            orientation="portrait"
          >
            <Box>this is an example {i}</Box>
          </Card>
        ))}
      </Slider>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Recommendations</title>;
